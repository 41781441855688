import axios from "axios";

import storage from "../components/helpers/storage";

const initialState = {
  isAuthenticated: false,
  token: null,
  isLoading: false,
  error: null,
};



export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS= "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const authLogout = () => {
  return {
    type: AUTH_LOGOUT,
  };
};

export const authStart = () => {
  return {
    type: AUTH_START,
  };
};

export const authFail = (error) => {
  return {
    type: AUTH_FAIL,
    error,
  };
};

export const authSuccess = (token) => {
  return {
    type: AUTH_SUCCESS,
    token,
  };
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
        token: action.token,
        error: null,
      };
    case AUTH_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: {},
        token: null,
        isLoading: false,
        error: null,
      };

      case FORGOT_PASSWORD:
        return{
            ...state,
            isAuthenticated: false,
            user: action.payload,
            token: action.token,
            error: null
        }

      case FORGOT_PASSWORD_SUCCESS:
        return {
          ...state,
          isAuthenticated: false,
          isLoading: false,
          user: action.payload,
          token: action.token,
          error: null,
        };

        case FORGOT_PASSWORD_FAIL:
          return {
            ...state,
            isLoading: false,
            error: action.payload,
          };


      case RESET_PASSWORD:
          return{
              ...state,
              isAuthenticated: false,
              user: action.payload,
              token: action.token,
              error: null
          }

          case RESET_PASSWORD_FAIL:
            return{
                ...state,
                isLoading: false,
                error: action.payload,
            }
            case RESET_PASSWORD_SUCCESS:
              return {
                ...state,
                isAuthenticated: false,
                isLoading: false,
                user: action.payload,
                token: action.token,
                error: null,
              };
    default:
      return state;
  }
};

export const authCheckState = (token, user) => {
  return (dispatch) => {
    if (token) {
      dispatch(authSuccess(token, user));
    }
  };
};

export const auth = (email, password) => {
  return (dispatch) => {
    dispatch(authStart());
    return new Promise((resolve, reject) => {
      const authData = {
        email,
        password,
      };
      axios
        .post("/auth/signin", authData)
        .then((res) => {
          const token = res.data.token;
          const userId = res.data.data.user._id;
          localStorage.setItem("client-token", token);
          localStorage.setItem("client-id", userId);
          console.log(res.data.token)
          localStorage.setItem('user',  res.data.data.user._id)
         storage.saveCredentials(res.data.token, res.data.data.user._id);
         storage.saveStatus(res.status);
          resolve(dispatch(authSuccess(token)));
        })
        .then(() => {
          dispatch(authCheckState());
        })
        .catch((err) => {
          // alert(err.response.data.message);
          dispatch(authFail( err.response.data.error ));
          reject( err.response.data.error );
        });
    });
  };
};

export const Logout = () => {
  return (dispatch) => {
    localStorage.removeItem("client-token");
    dispatch(authLogout());
  };
};

// FORGOT PASSWORD
export const forgotPassword = (error) => {
  return {
      type: FORGOT_PASSWORD,
      error
  }
};

export const forgotFail = (error) => {
    return {
      type: FORGOT_PASSWORD_FAIL,
      error,
    };
};

export const forgotSuccess = (token) => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    token,
  };
};

export const forgot = (email, callback) => {
    return (dispatch) => {
      dispatch(forgotPassword());
      return new Promise((resolve, reject) => {
        const forgotData = {
          email,
          callback,
        };
        axios
          .post("/auth/forgot-password ", forgotData)
          .then((res) => {
            const token = res.data.token;
            
            resolve(dispatch(forgotSuccess(token)));
          })
        //   .then(() => {
        //     dispatch(authCheckState());
        //   })
          .catch((err) => {
            // alert(err.response.data.message);
            dispatch(forgotFail( err.response.data.error ));
            reject( err.response.data.error );
          });
      });
    };
};

// RESET PASSWORD
export const resetPassword = (error) => {
    return {
        type: RESET_PASSWORD,
        error
    }
}

export const resetSuccess = (token) => {
    return {
      type: FORGOT_PASSWORD_SUCCESS,
      token,
    };
};

export const resetFail = (error) => {
    return {
        type: FORGOT_PASSWORD_FAIL,
        error
    }
}

export const reset = (password, password_confirmation) => {

    return (dispatch) => {
      dispatch(resetPassword());
      return new Promise((resolve, reject) => {
        const forgotData = {
          password,
          password_confirmation,
        };
        axios
          .post(`/auth/reset-password/${localStorage.getItem('client-token')}`, forgotData)
          .then((res) => {
            const token = res.data.token;
            
            resolve(dispatch(resetSuccess(token)));
          })
        //   .then(() => {
        //     dispatch(authCheckState());
        //   })
          .catch((err) => {
            // alert(err.response.data.message);
            dispatch(resetFail( err.response.data.error ));
            reject( err.response.data.error );
          });
      });
    };
};

export const AUTH_USER_FETCH_START = "AUTH_USER_FETCH_START";
export const AUTH_USER_FETCH_SUCCESS = "AUTH_USER_FETCH_SUCCESS";
export const AUTH_USER_FETCH_FAIL = "AUTH_USER_FETCH_FAIL";

export const authUserFetchStart = () => ({
  type: AUTH_USER_FETCH_START,
});

export const authUserFetchSuccess = (user) => ({
  type: AUTH_USER_FETCH_SUCCESS,
  user,
});

export const authUserFetchFail = (error) => ({
  type: AUTH_USER_FETCH_FAIL,
  error,
});

const initialState2 = {
  user: {},
  isLoading: false,
  error: null,
};

const authUserReducer = (state = initialState2, action) => {
  switch (action.type) {
    case AUTH_USER_FETCH_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case AUTH_USER_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.user,
        error: null,
      };
    case AUTH_USER_FETCH_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const authUserFetch = () => {
  return (dispatch) => {
    dispatch(authUserFetchStart());
    return new Promise((resolve, reject) => {
      axios
        .get(`auth/me`)
        .then((resp) => {
          resolve(dispatch(authUserFetchSuccess(resp.data.data)));
        })
        .catch((err) => {
            console.log('test', err)
          dispatch(authUserFetchFail(err.response.data.message));

          reject(err.response.data.message);
        });
    });
  };
};

export { authReducer, authUserReducer, authUserFetch };
