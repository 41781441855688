/* eslint-disable */
import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import "./components/utils/axios";
import { useDispatch } from "react-redux";
import { authUserFetch } from "./reducers/auth";
import { useSelector } from "react-redux";
import MainLoader from './components/helpers/MainLoader'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorUI from './components/layouts/globals/ErrorUI'
import ReactGA from 'react-ga'
import DashboardLayout from './components/layouts/globals/Dashboard'
import DashboardI from './components/layouts/globals/InstructorDash'
//context api states
import UserState from './context/user/userState'
import ResourceState from './context/resource/resourceState'
import CourseState from './context/course/courseState'
import CohortState from './context/cohort/cohortState'
import InstructorState from './context/instructor/instructorState'
import PhotoState from './context/photo/photoState'
import StudentState from './context/student/studentState';
import PublicState from './context/public/publicState'
import TransactionState from './context/transaction/transactionState';
// components: lazyload pages
const Home = React.lazy(() => import('./components/pages/Home'));
const Courses = React.lazy(() => import('./components/pages/Courses'));
const About = React.lazy(() => import('./components/pages/About'));
const Blog = React.lazy(() => import('./components/pages/blog/Blog'));
const Price = React.lazy(() => import('./components/pages/Pricing'));
const PayNow = React.lazy(() => import('./components/pages/stripe/CardSection'));
const ComingSoon = React.lazy(() => import('./components/pages/ComingSoom'));

const BlogDetails = React.lazy(() => import('./components/pages/blog/BlogDetails'));
const Events = React.lazy(() => import('./components/pages/Event'));
const EventDetails = React.lazy(() => import('./components/pages/EventDetails'));
const Contact = React.lazy(() => import('./components/pages/Contact'));
const Instructors = React.lazy(() => import('./components/pages/Instructor'));
const InstructorDetails = React.lazy(() => import('./components/pages/InstructorDetails'));
const FAQs = React.lazy(() => import('./components/pages/FAQs'));



const Login = React.lazy(() => import('./components/pages/auth/Login'));
const Register = React.lazy(() => import('./components/pages/auth/Register'));
const ResetPassword = React.lazy(() => import('./components/pages/auth/ResetPassword'));
const ForgotPassword = React.lazy(() => import('./components/pages/auth/ForgotPassword'));
const UpdatePassword = React.lazy(() => import('./components/pages/dashboard/student/profile/Update'));
const NotFound = React.lazy(() => import('./components/pages/404'));
const NetworkUi = React.lazy(() => import('./components/pages/NetworkUI'));


// Dashbaord
const Dashboard = React.lazy(() => import('./components/pages/dashboard/student/Home'));
const Profile = React.lazy(() => import('./components/pages/dashboard/student/profile/Profile'));
const ProfileDetails = React.lazy(() => import('./components/pages/dashboard/student/profile/ProfileDetails'));
const Wallet = React.lazy(() => import('./components/pages/dashboard/student/payment/paymentHistory'));
const PayDetails = React.lazy(() => import('./components/pages/dashboard/student/payment/paymentDetails'));
const PayPart = React.lazy(() => import('./components/pages/dashboard/student/payment/PartPayment'));
const Card = React.lazy(() => import('./components/pages/dashboard/student/payment/Card'));
const Contracts = React.lazy(() => import('./components/pages/dashboard/student/contracts/Contract'));

const Certificate = React.lazy(() => import('./components/pages/dashboard/student/certificate/Certificate'));
const CourseMaterial = React.lazy(() => import('./components/pages/dashboard/student/courses/CourseMaterial'));
const Notification = React.lazy(() => import('./components/pages/dashboard/student/notification/Notification'));

const Assignment = React.lazy(() => import('./components/pages/dashboard/student/Task/Assignments'));
// Instructor
const Instructor = React.lazy(() => import('./components/pages/dashboard/instructor/Home'));
const InstructorLogin = React.lazy(() => import('./components/pages/auth/InstructorRegister'));
const IProfile = React.lazy(() => import('./components/pages/dashboard/instructor/profile/Profile'));
const IStudent = React.lazy(() => import('./components/pages/dashboard/instructor/student/Student'));
const ISDetails = React.lazy(() => import('./components/pages/dashboard/instructor/student/StudentDetails'));
const DesignClass = React.lazy(() => import('./components/pages/DesignClass'))
const RegisterFreeCourse = React.lazy(() => import('./components/pages/RegisterFreeCourse'))


const Cert = React.lazy(() => import('./components/pages/dashboard/student/Certification'));




const App = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.authUser.user);
  const dispatch = useDispatch();

  const TRACKING_ID = "UA-184506492-1";

  ReactGA.pageview(window.location.pathname + window.location.search);


  ReactGA.initialize(TRACKING_ID);

  const errorHandler = (err, info) => {

    console.log(err, 'logged error');
    console.log(info, 'logged error info');

  }
  
  return(

    <Router>

      <UserState>

        <PublicState>

        <PhotoState>

        <CourseState>

        <CohortState>

          <InstructorState>


              <TransactionState>

                <StudentState>
                    <ResourceState>

            <Suspense fallback={MainLoader.MainLoader()}>

              <ErrorBoundary fallback={ErrorUI()} onError={errorHandler} >

                <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/courses" component={Courses} />
                <Route exact path="/about" component={About} />
                <Route exact path="/events" component={Events} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/instructors" component={Instructors} />
                <Route exact path="/instructor/:id" component={InstructorDetails} />
                <Route exact path="/faqs" component={FAQs} />
                <Route exact path="/coming-soon" component={ComingSoon} />
                <Route exact path="/free-course" component={DesignClass} />
                <Route exact path="/free-course/register" component={RegisterFreeCourse} />
                
                
                <Route exact path="/paynow/:iid" component={PayNow} />
                <Route exact path="/register" component={Register} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/login/instructor" component={InstructorLogin} />
                  <Route exact path="/blog" component={Blog} />
                  <Route exact path="/course/:id" component={Price} />
                  <Route exact path="/event/:id" component={EventDetails} />


                  <Route exact path="/blog/:id" component={BlogDetails} />

                  <Route exact path="/auth/password/reset/:token" component={ResetPassword} />
                  <Route exact path="/forgot-password" component={ForgotPassword} />
                  <Route exact path="/dashboard/settings/update-password" component={DashboardLayout(UpdatePassword, 'Update Password')} />
                  <Route exact path="/no-network" component={NetworkUi} />

                  <Route exact path="/dashboard/students/home" component={DashboardLayout(Dashboard, 'Home',)} />
                  <Route exact path="/dashboard/:courseId/certification/:id" component={DashboardLayout(Cert, 'Certificate', true)} />
                  <Route exact path="/dashboard/students/profile" component={DashboardLayout(Profile, 'Update Profile', true)} />
                  <Route exact path="/dashboard/students/profile/:id" component={DashboardLayout(ProfileDetails, 'Student Profile', true)} />
                  <Route exact path="/dashboard/payment/payment-history" component={DashboardLayout(Wallet, 'Payment History', true)} />
                  <Route exact path="/dashboard/payment/card" component={DashboardLayout(Card, 'Payment Part', true)} />

                  <Route exact path="/dashboard/payment/payment-details" component={DashboardLayout(PayDetails, 'Payment Details', true)} />
                  <Route exact path="/dashboard/contracts/contract" component={DashboardLayout(Contracts, 'Contracts', true)} />
                  <Route exact path="/dashboard/certificates/certificate" component={DashboardLayout(Certificate, 'Certificate', true)} />
                  <Route exact path="/dashboard/course/course-mateials" component={DashboardLayout(CourseMaterial, 'Course Material', true)} />

                  <Route exact path="/dashboard/notification/notifications" component={DashboardLayout(Notification, 'Notifications', true)} />

                  <Route exact path="/dashboard/assigments/assignment" component={DashboardLayout(Assignment, 'Assignment', true)} />
                  {/* iNSTRUCTORS */}

                  <Route exact path="/dashboard/instructors/home" component={DashboardI(Instructor, 'Instructor', true)} />
                  <Route exact path="/dashboard/instructors/profile" component={DashboardI(IProfile, 'Profile', true)} />
                  <Route exact path="/dashboard/instructors/students" component={DashboardI(IStudent, 'Students', true)} />
                  <Route exact path="/dashboard/instructors/students/:id" component={DashboardI(ISDetails, 'Students Details', true)} />

                  <Route exact path="*" component={NotFound} />
                </Switch>

              </ErrorBoundary>

            </Suspense>

                    </ResourceState>
                </StudentState>
              </TransactionState>

          </InstructorState>

        </CohortState>
          
        </CourseState>
  
        </PhotoState>

        </PublicState>
        
      </UserState>
                

    </Router>
    
  )
}

export default App;