import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ pageTitle, type }) => {

    const meta = {
        url: 'https://slearn.saidatech.com',
        title: 'Saidatech',
        description: 'Build your tech career by learning new technologies on saidatech',
        language: "en-US",
        image: 'https://storage.googleapis.com/saidatech-buckets/saidatech-seo',
        author: {
            email: 'info@saidatech.com',
            name: 'Saidatech Learn',
            image: 'https://storage.googleapis.com/saidatech-buckets/saidatech-seo',
        },
        site: {
            siteName: 'Saidatech Learn',
            searchUrl: 'https://www.google.com/search?q=Sadatech'
        }
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"></meta>
                <title>{meta.description && type === "main" ? pageTitle : ''}</title>
                <meta name="description" content={meta.description}></meta>
                <meta name="keywords" content="store, app"></meta>

                <meta itemprop="description" content={meta.description}></meta>
                <meta itemprop="image" content={meta.image}></meta>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:site" content="@Saidatech" />
                <meta name="twitter:creator" content="@Saidatech" />
                <meta name="twitter:title" content="Saidatech Learn"/>
                <meta name="twitter:description" content={meta.description}/>
                <meta name="twitter:image" content={meta.image}/>

                <meta property="og:site_name" content="Saidatech.com" />
                <meta property="og:title" content="Saidatech Learn"/>
                <meta property="og:description" content={meta.description}/>
                <meta property="og:image" content={meta.image}/>
                <meta property="og:url" content={meta.url} />
            </Helmet>
        </>
    )
}

export default SEO
