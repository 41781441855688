/* eslint-disable import/no-anonymous-default-export */
import {
    GET_COHORT,
    GET_ALL_COHORTS,
    GET_ALL_COHORT_DETAILS,
    GET_A_COHORT_DETAILS,
    SET_LOADING
} from '../types'

export default (state, action) => {

    switch (action.type) {
        case GET_ALL_COHORTS:
            return {
                ...state,
                cohorts: action.payload,
                loading: false
            }

        case GET_COHORT:
            return {
                ...state,
                cohort: action.payload,
                loading: false
            }

            case   GET_ALL_COHORT_DETAILS:
                return {
                    ...state,
                    cohort_details: action.payload,
                    loading: false
                }

                case   GET_A_COHORT_DETAILS:
                    return {
                        ...state,
                        a_cohort_details: action.payload,
                        loading: false
                    }
        case SET_LOADING:
            return {
                ...state,
                loading: true
            }

            default: 
                return state;
    }
}