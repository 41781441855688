import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import localforage from "localforage";
import thunk from "redux-thunk";
import logger from "redux-logger";
// import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "./reducers";
const rootReducer = combineReducers({
  auth: reducers.auth,
  authUser: reducers.authUser,
  forgot: reducers.forgot,
  update: reducers.update,
  Reset: reducers.Reset,
//   projects: reducers.projects,
});
   
const persistConfig = {
  key: "saidatech-client-key",
  storage: localforage,
  // stateReconciler: hardSet,

};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(thunk, logger));
const persistor = persistStore(store);
export { store, persistor };
