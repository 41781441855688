import axios from "axios";

import storage from "../components/helpers/storage";
const initialState = {
  isAuthenticated: false,
  token: null,
  isLoading: false,
  error: null,
};


export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";


export const updateProfile = () => {
  return {
    type: UPDATE_PROFILE,
  };
};

export const updateProfileFail = (error) => {
  return {
    type: UPDATE_PROFILE_FAIL,
    error,
  };
};

export const updateProfileSucess = (token) => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    token,
  };
};

const updateReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROFILE :
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
        token: action.token,
        error: null,
      };
    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      default:
        return state;
    
  }
};

export { updateReducer};


export const update = (email, password, firstName,lastName,gender,bio,file,fileName) => {
  return (dispatch) => {
    dispatch(updateProfile());
    return new Promise((resolve, reject) => {
      const updateData = {
        email, 
        firstName,
        lastName,
        password,
        gender,
        bio,
        fileName,
        file,
      };
      axios
        .put(`/users/update/${storage.getUserID()}`, updateData, storage.getConfigWithBearer())
        .then((res) => {
          const token = res.data.token;
         
     
          resolve(dispatch(updateProfileSucess(token)));
        })
        // .then(() => {
        //   dispatch(authCheckState());
        // })
        .catch((err) => {
          // alert(err.response.data.message);
          dispatch(updateProfileFail( err.response.data.error ));
          reject( err.response.data.error );
        });
    });
  };
};


