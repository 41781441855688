import axios from "axios";
const { REACT_APP_URL } = process.env;
axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
  "token"
)}`;

// if (process.env.NODE_ENV === "development") {
//   axios.defaults.baseURL = REACT_APP_LOCAL_API_URL;
// } else {
axios.defaults.baseURL = REACT_APP_URL;
// }
