import React, { useReducer } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import storage from '../../components/helpers/storage'
import loader from '../../components/helpers/MainLoader'

import CohortContext from "./cohortContext";
import CohortReducer from "./cohortReducer";

import {
    GET_COHORT,
    GET_ALL_COHORTS,
    GET_ALL_COHORT_DETAILS,
    GET_A_COHORT_DETAILS,
    SET_LOADING
} from '../types';

const CohortState = (props) => {

    const history = useHistory();

    Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

    const initialState = {
        cohort: {},
        cohort_details: [],
        cohorts: [],
        a_cohort_details: {},
        loading: false
    };

    const [state, dispatch] = useReducer(CohortReducer, initialState);

    const logout = async (e) => {
        if (e) e.preventDefault();

        storage.clearAuth();
        history.push('/');

    };

    const getAllCohorts = async () => {
        setLoading()
        try {

            await Axios.get(`${process.env.REACT_APP_URL}/cohorts`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_ALL_COHORTS,
                    payload: resp.data.data
                });

            }).catch((err) => {

                if(err && err.response && err.response.data && err.response.status === 401){

                    logout();
    
                }else if(err && err.response && err.response.data){
    
                    console.log(`Error! Could not get all cohorts ${err.response.data}`)
    
                }else if(err && err.toString() === 'Error: Network Error'){
    
                    loader.popNetwork();
    
                }else if(err){
    
                    console.log(`Error! Could not get cohorts ${err}`)
    
                }
                
            })
            
        } catch (err) {
            
            if(err && err.response && err.response.data && err.response.data.status === 401){

                logout();

            }else if(err && err.response && err.response.data){

                console.log(`Error! Could not get cohorts ${err.response.data}`)

            }else if(err && err.toString() === 'Error: Network Error'){

                loader.popNetwork();

            }else if(err){

                console.log(`Error! Could not get cohorts ${err}`)

            }
            
        }
    };
    
    const getCohort = async (id) => {
        setLoading()
        try {

            await Axios.get(`${process.env.REACT_APP_URL}/cohorts/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_COHORT,
                    payload: resp.data.data
                });

            }).catch((err) => {

                if(err && err.response && err.response.data && err.response.data.status === 401){

                    logout();
    
                }else if(err && err.response && err.response.data){
    
                    console.log(`Error! Could not get cohort ${err.response.data}`)
    
                }else if(err && err.toString() === 'Error: Network Error'){
    
                    loader.popNetwork();
    
                }else if(err){
    
                    console.log(`Error! Could not get cohort ${err}`)
    
                }
                
            })
            
        } catch (err) {
            
            if(err && err.response && err.response.data && err.response.data.status === 401){

                logout();

            }else if(err && err.response && err.response.data){

                console.log(`Error! Could not get cohort ${err.response.data}`)

            }else if(err && err.toString() === 'Error: Network Error'){

                loader.popNetwork();

            }else if(err){

                console.log(`Error! Could not get cohort ${err}`)

            }
            
        }
    };


    const getCohortDetail = async () => {
        setLoading()
        try {

            await Axios.get(`${process.env.REACT_APP_ADMIN_URL}/cohort-details`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_ALL_COHORT_DETAILS,
                    payload: resp.data.data
                });

            }).catch((err) => {

                if(err && err.response && err.response.data && err.response.status === 401){

                    logout();
    
                }else if(err && err.response && err.response.data){
    
                    console.log(`Error! Could not get all cohorts ${err.response.data}`)
    
                }else if(err && err.toString() === 'Error: Network Error'){
    
                    loader.popNetwork();
    
                }else if(err){
    
                    console.log(`Error! Could not get cohorts ${err}`)
    
                }
                
            })
            
        } catch (err) {
            
            if(err && err.response && err.response.data && err.response.data.status === 401){

                logout();

            }else if(err && err.response && err.response.data){

                console.log(`Error! Could not get cohorts ${err.response.data}`)

            }else if(err && err.toString() === 'Error: Network Error'){

                loader.popNetwork();

            }else if(err){

                console.log(`Error! Could not get cohorts ${err}`)

            }
            
        }
    };

    const getCohortDetails = async (id) => {
        setLoading()
        try {

            await Axios.get(`${process.env.REACT_APP_URL}/cohort-details/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_A_COHORT_DETAILS,
                    payload: resp.data.data
                });

            }).catch((err) => {

                if(err && err.response && err.response.data && err.response.data.status === 401){

                    logout();
    
                }else if(err && err.response && err.response.data){
    
                    console.log(`Error! Could not get cohort ${err.response.data}`)
    
                }else if(err && err.toString() === 'Error: Network Error'){
    
                    loader.popNetwork();
    
                }else if(err){
    
                    console.log(`Error! Could not get cohort ${err}`)
    
                }
                
            })
            
        } catch (err) {
            
            if(err && err.response && err.response.data && err.response.data.status === 401){

                logout();

            }else if(err && err.response && err.response.data){

                console.log(`Error! Could not get cohort ${err.response.data}`)

            }else if(err && err.toString() === 'Error: Network Error'){

                loader.popNetwork();

            }else if(err){

                console.log(`Error! Could not get cohort ${err}`)

            }
            
        }
    };
    const setLoading = () => {
        dispatch({
            type: SET_LOADING,
        })
    }

    return <CohortContext.Provider
        value={{
            cohort: state.cohort,
            cohorts: state.cohorts,
            cohort_details: state.cohort_details,
            a_cohort_details: state.a_cohort_details,
            loading: state.loading,
            getAllCohorts,
            getCohort,
            getCohortDetails,
            getCohortDetail
        
        }}
    >
        {props.children}
    </CohortContext.Provider>
};

export default CohortState;