
import React, { Suspense } from "react";
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from "react-router-dom";
import MainLoader from './components/helpers/MainLoader'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from "./reducers/store";
import { Provider}  from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
  <React.StrictMode>
   <Suspense fallback={MainLoader.MainLoader()}>
      <Provider store={store}>
        <PersistGate loading={MainLoader.MainLoader()} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        </PersistGate>
        </Provider>
       
        </Suspense>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



