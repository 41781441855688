export const GET_LOGGEDIN_USER = 'GET_LOGGEDIN_USER';
export const GET_USER = 'GET_USER';
export const GET_ALL_INSTRUCTORS = 'GET_ALL_INSTRUCTORS';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_BANKS = 'GET_BANKS';
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_IP_ADDRESS = 'GET_IP_ADDRESS';
export const GET_COHORT = "GET_COHORT";
export const GET_ALL_COHORTS = "GET_ALL_COHORTS";
export const GET_ALL_COHORT_DETAILS = "GET_ALL_COHORTS_DETAILS";
export const GET_A_COHORT_DETAILS = "GET_A_COHORTS_DETAILS";


export const GET_A_STUDENT = 'GET_A_STUDENT';
export const GET_STUDENTS = 'GET_STUDENTS';
export const GET_STUDENT = 'GET_STUDENT';
export const GET_STUDENT_CLASS = 'GET_STUDENT_CLASS';
export const GET_STUDENT_ASSIGNMENT = 'GET_STUDENT_ASSIGNMENT';
export const GET_STUDENT_TASK = 'GET_STUDENT_TASK';
export const GET_STUDENT_CONTRACT = 'GET_STUDENT_CONTRACT';

export const GET_AN_INSTRUCTOR = 'GET_AN_INSTRUCTOR';
export const GET_INSTRUCTORS = 'GET_INSTRUCTORS';
export const GET_INSTRUCTOR_CLASS = 'GET_INSTRUCTOR_CLASS';

export const GET_ASSIGNMENT = 'GET_ASSIGNMENT';
export const GET_ALL_ASSIGNMENTS = 'GET_ALL_ASSIGNMENTS'

export const GET_ALL_TRANSACTIONS = 'GET_ALL_TRANSACTIONS';
export const GET_A_TRANSACTION = 'GET_A_TRANSACTION';
export const GET_STUDENT_TRANSAC = 'GET_STUDENT_TRANSAC';

export const GET_TASK = 'GET_TASK';
export const GET_ALL_TASK = 'GET_ALL_TASK';

export const GET_A_BLOG = 'GET_A_BLOG';
export const GET_BLOGS = 'GET_BLOGS';

export const GET_TESTIMONIAL = 'GET_TESTIMONIAL';
export const GET_ALL_TESTIMONIALS = 'GET_ALL_TESTIMONIALS';

export const GET_AN_EVENT = 'GET_AN_EVENT';
export const GET_EVENTS = 'GET_EVENTS';

export const GET_A_COURSE = 'GET_AN_COURSE';
export const GET_COURSES = 'GET_COURSES';
export const SET_COURSES_PAGINATION = 'SET_COURSES_PAGINATION';
export const GET_LIMIT__COURSES = 'GET_LIMIT__COURSES';

export const GET_ALL_PAYMENT = 'GET_ALL_PAYMENTS';
export const GET_STUDENT_PAYMENT = 'GET_STUDENT_PAYMENT';
export const GET_BALANCE_PAYMENT = 'GET_BALANCE_PAYMENT';
export const GET_PAYMENT = 'GET_PAYMENT';

export const GET_CONTRACT = 'GET_CONTRACT';
export const GET_ALL_CONTRACTS = 'GET_ALL_CONTRACTS';

export const GET_UNSPLASH_PHOTOS = 'GET_UNSPLASH_PHOTOS';

// generics
export const SET_LOADING = 'SET_LOADING';
export const UNSET_LOADING = 'UNSET_LOADING';