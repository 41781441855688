import { authReducer, authUserReducer } from "./auth";
import { updateReducer } from "./updateProfile";
const reducers = {
  auth: authReducer,
  authUser: authUserReducer,
  update: updateReducer,
  forgot: authReducer,
  reset: authReducer
  
  // projects: projectReducer,
};
export default reducers;